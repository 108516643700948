import "./TitlePage.css"

function TitlePage({ label }) {
    return (
        <div className="title-page-container">
            <div className="custom-border-top" />
            <p className="mb-4">{label}</p>
        </div >
    )
}

export default TitlePage
