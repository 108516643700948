import { WarningAmber } from '@mui/icons-material'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function InfoBanner() {
    return (
        <Navbar id="info-banner-navbar" fixed="top" className="info-banner-navbar">
            <Container className="justify-content-end justify-content-md-between">
                <Navbar.Brand><Link to="covid-information"><WarningAmber /> COVID-19: Informasi dan Update</Link></Navbar.Brand>
                <Nav>
                    <Link
                        className="nav-link"
                        to="new-information"
                    >
                        Informasi Terbaru
                    </Link>
                    <Link
                        className="nav-link"
                        to="webmail"
                    >
                        Webmail
                    </Link>
                    <Link
                        className="nav-link"
                        to="directory"
                    >
                        Direktori
                    </Link>
                    <NavDropdown title={<img src="/img/indonesia.png" alt="Flag of Indonesia" />} id="navbarScrollingDropdown">
                    </NavDropdown>
                </Nav>
            </Container>
        </Navbar>
    )
}

export default InfoBanner
