import { Button, Col, Container, Row } from "react-bootstrap"
import { useNavigate } from "react-router"
import Jumbotron from "../components/Jumbotron"
import { NavLink } from "react-router-dom";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import "./Berita.css"

function Berita() {
    const navigate = useNavigate()

    const news = [{
        title: "BRI Cabang Padang Bantu Beasisiwa Rp 50 Juta Mahasiswa UNES-AAI",
        image: "/img/news-1.png",
        date: "17 Juli 2020"
    }, {
        title: "Lawan Berita Hoax, Hima Administrasi Negara UNES Gelar Seminar Nasional",
        image: "/img/news-2.png",
        date: "1 November 2019"
    }, {
        title: "Prodi THP Fakultas Pertanian UNES Gelar Workshop Mendeley ",
        image: "/img/news-3.png",
        date: "9 Oktober 2019"
    }]


    const handleClickMainNewsReadMore = () => {
        navigate("/berita/detail")
    }

    return (
        <div>
            <Jumbotron
                slides={[{
                    title: "BERITA",
                    image: "/img/jumbotron-news.png",
                    isBottomStyle: true
                }]}
            />
            <div className="news-content-container">
                <Container className="news py-5">
                    <Row>
                        <Col xs={12} className="mb-5">
                            <div className="main-news d-flex align-items-center">
                                <Row>
                                    <Col xs={12} className="mb-3 mb-md-0">
                                        <img src="/img/main-news-1.png" />
                                    </Col>
                                    <Col xs={12}>
                                        <div className="d-md-block d-none position-absolute end-0 w-50">
                                            <div className="title-card">
                                                <p className="date">17 JULI 2020</p>
                                                <p className="title">Andaleh Nagari Binaan UNES-AAI Produksi Roti Bakery, Aluo dan Rakik</p>
                                                <Button className="unes-btn" onClick={handleClickMainNewsReadMore}>Baca Selengkapnya</Button>
                                            </div>
                                        </div>
                                        <div className="d-md-none d-block title-card">
                                            <p className="date">17 JULI 2020</p>
                                            <p className="title">Andaleh Nagari Binaan UNES-AAI Produksi Roti Bakery, Aluo dan Rakik</p>
                                            <Button className="unes-btn" onClick={handleClickMainNewsReadMore}>Baca Selengkapnya</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <OwlCarousel
                                className="owl-theme"
                                margin={30}
                                dots={false}
                                responsive={{
                                    0: {
                                        items: 1
                                    },
                                    600: {
                                        items: 2
                                    },
                                    800: {
                                        items: 3
                                    }
                                }}
                            >
                                {news.map((datum, index) =>
                                    <div key={index} className="news-card d-flex align-items-end" style={{ backgroundImage: `url('${datum.image}')` }}>
                                        <div className="news-detail-info">
                                            <p className="title">{datum.title}</p>
                                            <p className="date">{datum.date}</p>
                                            <NavLink className="read-more" to="/berita/detail">Baca Selengkapnya</NavLink>
                                        </div>
                                    </div>
                                )}
                            </OwlCarousel>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Berita
