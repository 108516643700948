import { useState } from "react"
import { Accordion, Col, Container, Row } from "react-bootstrap"
import { useNavigate } from "react-router"
import Content from "../components/Content"
import Jumbotron from "../components/Jumbotron"
import TitlePage from "../components/TitlePage"

import "./Akademik.css"

function Akademik() {
    const [activeHeader, setActiveHeader] = useState("SARJANA")
    const navigate = useNavigate()

    const handleChangeHeader = currentHeader => () => {
        setActiveHeader(currentHeader)
    }

    const programs = [{
        title: "SARJANA",
        secondTitle: "Fakultas",
        faculties: [{
            title: "Fakultas Keguruan dan Ilmu Pendidikan",
            img: "/img/fakultas-1.png"
        }, {
            title: "Fakultas Ekonomi",
            img: "/img/fakultas-2.png"
        }, {
            title: "Fakultas Hukum",
            img: "/img/fakultas-3.png"
        }, {
            title: "Fakultas Pertanian",
            img: "/img/fakultas-4.png"
        }, {
            title: "Fakultas Teknik",
            img: "/img/fakultas-5.png"
        }, {
            title: "Fakultas Sastra",
            img: "/img/fakultas-6.png"
        }, {
            title: "Fakultas Ilmu Sosial dan Ilmu Politik",
            img: "/img/fakultas-7.png"
        }]
    }, {
        title: "PASCASARJANA",
        secondTitle: "Fakultas",
        faculties: [{
            title: "Fakultas Hukum",
            img: "/img/fakultas-3.png"
        }]
    }, {
        title: "DIPLOMA",
        secondTitle: "Diploma",
        faculties: [{
            title: "Manajemen Informatika",
            img: "/img/diploma-1.png"
        }]
    }, {
        title: "AKADEMI AKUNTANSI INDONESIA",
        secondTitle: "Akademik",
        img: "/img/akuntansi-indonesia-1.png"
    }]

    const activeProgram = programs.find(program => program.title === activeHeader)


    const handleClickFacultyCard = () => {
        if (activeProgram) {
            navigate("/faculty/" + activeProgram.title)
        }
    }


    return (
        <div>
            <Jumbotron
                slides={[{
                    title: "AKADEMIK",
                    subtitle: `PROGRAM ${activeHeader}`,
                    image: "/img/jumbotron-akademik.png",
                    isBottomStyle: true
                }]}
            />
            <div className="akademik-content-container">
                <Container className="py-5">
                    <Row>
                        <Col md={5}>
                            <Accordion defaultActiveKey="0" className="mb-2 mb-md-0">
                                {programs.map((program, index) => {
                                    return (
                                        <Accordion.Item key={index} eventKey={index} onClick={handleChangeHeader(program.title)}>
                                            <Accordion.Header className={Array.isArray(program.faculties) && program.faculties.length > 0 ? "" : "hide-arrow"}>{program.title}</Accordion.Header>
                                            {program.faculties &&
                                                <Accordion.Body>
                                                    {program.faculties.map((faculty, index) =>
                                                        <button key={index} >{faculty.title}</button>
                                                    )}
                                                </Accordion.Body>
                                            }
                                        </Accordion.Item>
                                    )
                                })}
                            </Accordion>
                        </Col>
                        <Col md={7}>
                            <Content
                                description={
                                    <>
                                        But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.
                                        <br />
                                        <br />
                                        No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.
                                        <br />
                                        <br />
                                        To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure? On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain.
                                        <br />
                                        <br />
                                        But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.
                                        <br />
                                        <br />
                                        No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.
                                    </>
                                }
                            />
                        </Col>
                        {activeProgram &&
                            <Col xs={12} className="mt-5 active-program">
                                <Row>
                                    <Col xs={12} className="mb-3">
                                        <TitlePage label={activeProgram.secondTitle} />
                                    </Col>
                                    <Col xs={12}>
                                        <Row>
                                            {activeProgram.faculties && activeProgram.faculties.map((faculty, index) =>
                                                <Col key={index} sm={4} md={3} className="faculty-card" onClick={handleClickFacultyCard}>
                                                    <img src={faculty.img} />
                                                    <p>{faculty.title}</p>
                                                </Col>
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Akademik
