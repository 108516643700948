import { Button, Carousel, Col, Container, Row } from "react-bootstrap"
import Content from "../components/Content"
import Jumbotron from "../components/Jumbotron"
import TitlePage from "../components/TitlePage"

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import "./Home.css"
import { Link } from "react-router-dom";

function Home() {
    return (
        <div>
            <Jumbotron
                slides={[{
                    title: <>UNIVERSITAS EKASAKTI<br /> AKADEMI AKUNTANSI INDONESIA </>,
                    subtitle: <>INSTITUSI TERAKREDITASI B <br />NO SK. 246/SK/BAN-PT/Akred/PT/X/2018</>,
                    image: "/img/jumbotron-homepage.png"
                }]}
                withSocialMedia
                withNews
            />
            <div className="home-content-container">
                <Container className="profile py-5">
                    <Row>
                        <Col xs={12} md={6} className="mb-5 mb-md-0 pe-5">
                            <Row>
                                <Col xs={12} className="px-md-5">
                                    <TitlePage label="Profil" />
                                </Col>
                                <Col xs={12} className="mb-md-5 px-md-5">
                                    <Content
                                        title="UNES-AAI"
                                        description="Merupakan salah satu perguruan tinggi swasta yang berada di bawah naungan Yayasan Perguruan Tinggi Indonesia (YPTI). Kini Yayasan Perguruan Tinggi Padang (YPTP, sejak 2008). Perguruan Tinggi ini didirikan dan dipimpin oleh Prof Dr H. Andi Mustari Pide, S.H (Alm), bersama dengan DR.Hj.Erawati Toelis, M.M (Alm), berdiri pada bulan April 1973 di Padang. Pada awalnya tahun 1973 itu, YPTI mendirikan Akademi Akuntansi Indonesia Padang."
                                        readMoreProps={{
                                            to: "/read-more"
                                        }}
                                    />
                                </Col>
                                <Col xs={12} className="d-none d-md-block">
                                    <img src="/img/profile-image-2.png" />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={6} className="mb-5">
                            <img src="/img/profile-image-1.png" />
                        </Col>
                        <Col xs={12} className="d-md-none">
                            <img src="/img/profile-image-2.png" />
                        </Col>
                    </Row>
                </Container>
                <Container className="agenda py-5 mb-5">
                    <Row>
                        <Col xs={12} className="mb-3">
                            <TitlePage label="Agenda" />
                        </Col>
                        <Col xs={12}>
                            <Carousel controls={false}>
                                <Carousel.Item className="agenda-card">
                                    <Row>
                                        <Col xs={6} className="d-none d-md-block">
                                            <img src="/img/agenda-card-1.png" />
                                        </Col>
                                        <Col xs={12} md={6} className="d-flex flex-column justify-content-center p-5">
                                            <p className="date mb-0">11 Juni 2018</p>
                                            <p className="title">Penerimaan Mahasiswa Tahun Akademik 2018</p>
                                            <Button className="unes-btn">Baca Selengkapnya</Button>
                                        </Col>
                                    </Row>
                                </Carousel.Item>
                                <Carousel.Item className="agenda-card">
                                    <Row>
                                        <Col xs={6} className="d-none d-md-block">
                                            <img src="/img/agenda-card-1.png" />
                                        </Col>
                                        <Col xs={12} md={6} className="d-flex flex-column justify-content-center p-5">
                                            <p className="date mb-0">11 Juni 2018</p>
                                            <p className="title">Penerimaan Mahasiswa Tahun Akademik 2018</p>
                                            <Button className="unes-btn">Baca Selengkapnya</Button>
                                        </Col>
                                    </Row>
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
                <div className="akademik py-5 mb-5 container-md">
                    <Row>
                        <Col xs={12} md={6} className="mb-5 mb-md-0 pe-5">
                            <Container>
                                <Row>
                                    <Col xs={12}>
                                        <TitlePage label="Akademik" />
                                    </Col>
                                    <Col xs={12}>
                                        <Content
                                            description="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus."
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col xs={12} md={6}>
                            <Row className="d-none d-md-flex align-items-center justify-content-center">
                                <Col xs={4}>
                                    <div className="akademik-box d-flex align-items-center justify-content-center">
                                        DIPLOMA
                                    </div>
                                </Col>
                                <Col xs={4}>
                                    <Row>
                                        <Col xs={12} className="pb-4">
                                            <div className="akademik-box d-flex align-items-center justify-content-center">
                                                SARJANA
                                            </div>
                                        </Col>
                                        <Col xs={12}>
                                            <div className="akademik-box d-flex align-items-center justify-content-center">
                                                PASCASARJANA
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={4}>
                                    <div className="akademik-box d-flex align-items-center justify-content-center">
                                        AKADEMI AKUNTANSI INDONESIA
                                    </div>
                                </Col>
                            </Row>
                            <OwlCarousel className="owl-theme d-md-none" loop margin={30} center dots={false}>
                                <div className="item akademik-box d-flex align-items-center justify-content-center">
                                    DIPLOMA
                                </div>
                                <div className="item akademik-box d-flex align-items-center justify-content-center">
                                    SARJANA
                                </div>
                                <div className="item akademik-box d-flex align-items-center justify-content-center">
                                    PASCASARJANA
                                </div>
                                <div className="item akademik-box d-flex align-items-center justify-content-center">
                                    AKADEMI AKUNTANSI INDONESIA
                                </div>
                            </OwlCarousel>
                        </Col>
                    </Row>
                </div>
                <Container className="pimpinan-universitas py-5 mb-5">
                    <Row>
                        <Col xs={12} className="mb-5">
                            <TitlePage label="Pimpinan Universitas" />
                        </Col>
                        <Col xs={12}>
                            <OwlCarousel
                                className="owl-theme"
                                margin={30}
                                dots={false}
                                responsive={{
                                    0: {
                                        items: 1
                                    },
                                    400: {
                                        items: 2
                                    },
                                    600: {
                                        items: 3
                                    },
                                    800: {
                                        items: 4
                                    }
                                }}
                            >
                                <div className="item d-flex flex-column align-items-center">
                                    <img src="/img/rektor.png" />
                                    <p className="lecturer-name">Dr. Otong Rosadi, SH., M.H.</p>
                                    <p className="lecturer-job">Rektor</p>
                                </div>
                                <div className="item d-flex flex-column align-items-center">
                                    <img src="/img/warek-1.png" />
                                    <p className="lecturer-name">Dr. H. Agussalim M., SE., MS</p>
                                    <p className="lecturer-job">Wakil Rektor I</p>
                                </div>
                                <div className="item d-flex flex-column align-items-center">
                                    <img src="/img/warek-2.png" />
                                    <p className="lecturer-name">Ir. Prima Novia, MP</p>
                                    <p className="lecturer-job">Wakil Rektor II</p>
                                </div>
                                <div className="item d-flex flex-column align-items-center">
                                    <img src="/img/warek-3.png" />
                                    <p className="lecturer-name">Ir. Mahmud, M.Si</p>
                                    <p className="lecturer-job">Wakil Rektor III</p>
                                </div>
                            </OwlCarousel>
                        </Col>
                        <Col xs={12} className="d-flex align-items-center justify-content-center mt-4">
                            <Button className="unes-btn">Lihat Semua</Button>
                        </Col>
                    </Row>
                </Container>
                <Container className="what-they-say py-5 mb-5">
                    <Row>
                        <Col xs={12} className="mb-3">
                            <TitlePage label="Apa Kata Mereka" />
                        </Col>
                        <Col xs={12}>
                            <OwlCarousel
                                className="owl-theme"
                                margin={30}
                                dots={false}
                                nav
                                navText={[<img src="/img/arrow-owl-carousel-next.svg" />, <img src="/img/arrow-owl-carousel-prev.svg" />]}
                                responsive={{
                                    0: {
                                        items: 1
                                    },
                                    600: {
                                        items: 2
                                    },
                                    800: {
                                        items: 3
                                    }
                                }}
                            >
                                <div className="item d-flex flex-column align-items-center">
                                    <div className="user-review-container d-flex flex-column align-items-center">
                                        <div className="quote-box d-flex flex-column align-items-center">
                                            <img src="/icon/quote.svg" />
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                        </div>
                                        <img className="triangle-comment" src="/img/triangle-comment.svg" />
                                        <div className="profile-container d-flex flex-column align-items-center">
                                            <img src="/img/user-avatar-1.png" />
                                            <h5>Nama</h5>
                                            <h6>Mahasiswa</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="item d-flex flex-column align-items-center">
                                    <div className="user-review-container d-flex flex-column align-items-center">
                                        <div className="quote-box d-flex flex-column align-items-center">
                                            <img src="/icon/quote.svg" />
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                        </div>
                                        <img className="triangle-comment" src="/img/triangle-comment.svg" />
                                        <div className="profile-container d-flex flex-column align-items-center">
                                            <img src="/img/user-avatar-2.png" />
                                            <h5>Nama</h5>
                                            <h6>Mahasiswa</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="item d-flex flex-column align-items-center">
                                    <div className="user-review-container d-flex flex-column align-items-center">
                                        <div className="quote-box d-flex flex-column align-items-center">
                                            <img src="/icon/quote.svg" />
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                        </div>
                                        <img className="triangle-comment" src="/img/triangle-comment.svg" />
                                        <div className="profile-container d-flex flex-column align-items-center">
                                            <img src="/img/user-avatar-3.png" />
                                            <h5>Nama</h5>
                                            <h6>Mahasiswa</h6>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </Col>
                    </Row>
                </Container>
                <div className="kelas-digital py-5 mb-5">
                    <Col xs={12} className="mb-3">
                        <Container>
                            <TitlePage label="Kelas Digital" />
                        </Container>
                    </Col>
                    <Col xs={12}>
                        <div className="content bg-xs-transparent">
                            <Container>
                                <Row>
                                    <Col xs={12} md={6} className="d-flex align-items-center">
                                        <img src="/img/kelas-digital-cover.png" />
                                    </Col>
                                    <Col xs={12} md={6} className="pe-3 pe-md-0 py-3">
                                        <div className="d-flex flex-column justify-content-between h-100 py-3">
                                            <p>
                                                Kelas Digital adalah cara baru belajar menggunakan perangkat sehari-hari yang dapat membantu pendidikan di era baru ini, Mulai dari :
                                            </p>
                                            <ul>
                                                <li> Interaktif Kalender</li>
                                                <li> Dasbor Dosen</li>
                                                <li> Dasbor Mahasiswa</li>
                                                <li> Absensi dan masih banyak fitur lainnya</li>
                                            </ul>
                                            <Link className="read-more" to="/">Baca Selengkapnya</Link>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </div>
            </div >
        </div >
    )
}

export default Home
