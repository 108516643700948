import { EmailOutlined } from "@mui/icons-material"

function Footer() {
    return (
        <footer>
            <div className="footer" id="footer" style={{ backgroundImage: "url('/img/motif-footer.svg')" }}>
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-3 col-md-3 col-sm-12 col-12 py-3">
                            <div className="row">
                                <div className="col-12 mb-4">
                                    <img
                                        src="/img/unes_logo.svg"
                                        className="d-inline-block align-top"
                                        alt=""
                                        loading="lazy"
                                    />
                                </div>
                                <div className="col-12 university-detail">
                                    <h3>UNIVERSITAS EKASAKTI</h3>
                                    <p className="mb-4 mb-sm-3">
                                        Jl. Veteran No. 26B Purus, Kec. Padang Barat
                                    </p>
                                    <div className="d-flex align-items-end">
                                        <EmailOutlined />
                                        <a href="mailto:pmb@unespadang.ac.id"> pustikom@unespadang.ac.id</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 py-3">
                            <div className="row justify-content-end">
                                <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                                    <h3>Tautan Terkait</h3>
                                    <ul>
                                        <li><a href="#"> PDDIKTI </a></li>
                                        <li><a href="#"> Alumni Tracer </a></li>
                                        <li><a href="#"> Karir & Loker </a></li>
                                        <li><a href="#"> Sigap Bencana </a></li>
                                        <li><a href="#"> Pasa Gadang</a></li>
                                        <li><a href="#"> Sumbangan </a></li>
                                        <li><a href="#"> Sister (DIKTI) </a></li>
                                    </ul>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                                    <h3>Tentang UNES</h3>
                                    <ul>
                                        <li><a href="#"> Sambutan Rektor </a></li>
                                        <li><a href="#"> Sejarah </a></li>
                                        <li><a href="#"> Visi dan Misi </a></li>
                                        <li><a href="#"> Pimpinan Universitas </a></li>
                                        <li><a href="#"> Manajemen </a></li>
                                    </ul>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                                    <h3>Mengunjungi Unes</h3>
                                    <ul>
                                        <li><a href="#"> Peta Kampus </a></li>
                                        <li><a href="#"> Agenda </a></li>
                                    </ul>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                                    <h3>Pendaftaran</h3>
                                    <ul>
                                        <li><a href="#"> Sarjana </a></li>
                                        <li><a href="#"> Pascasarjana </a></li>
                                        <li><a href="#"> Diploma </a></li>
                                        <li><a href="#"> AAI </a></li>
                                        <li><a href="#"> Kelas Merdeka </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-bottom clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <p className="mb-1 mb-3 mb-sm-0 text-center text-white univ-copyright">
                                @2021 UNIVERSITAS EKASAKTI
                            </p>
                        </div>
                        <div className="col-12 col-sm-6">
                            <p className="mb-0 text-center rule-of-use">
                                <a href="/syarat&ketentuan" className="text-white text-decoration-none">
                                    ATURAN PENGUNAAN
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
