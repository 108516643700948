import React from 'react'
import ReactDOM from 'react-dom/client'
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom"

import App from './App'
import Home from './pages/Home'
import Profile from './pages/Profile'
import NotFound from './pages/NotFound'
import reportWebVitals from './reportWebVitals'

import 'bootstrap/dist/css/bootstrap.min.css'
import Akademik from './pages/Akademik'
import Berita from './pages/Berita'
import BeritaDetail from './pages/BeritaDetail'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path="/" element={<App />}>
                    <Route index element={<Home />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="akademik" element={<Akademik />} />
                    <Route path="berita" element={<Berita />} />
                    <Route path="berita/detail" element={<BeritaDetail />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </Router>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
