import Jumbotron from "../components/Jumbotron"

function NotFound() {
    return (
        <Jumbotron
            slides={[{
                title: "PAGE NOT FOUND",
                image: "/img/not-found.jpg"
            }]}
        />
    )
}

export default NotFound
