import { Link } from "react-router-dom"
import "./Content.css"

function Content({ title, description, readMoreProps }) {
    return (
        <div className="content-container">
            <p className="title">{title}</p>
            <p className="description pb-md-3">
                {description}
            </p>
            {readMoreProps &&
                <Link className="read-more" {...readMoreProps}>Baca Selengkapnya</Link>
            }
        </div>
    )
}

export default Content
