import { Col, Container, Row } from "react-bootstrap"
import Content from "../components/Content"
import Jumbotron from "../components/Jumbotron"
import TitlePage from "../components/TitlePage"

import "./Profile.css"

function Profile() {
    return (
        <div>
            <Jumbotron
                slides={[{
                    title: "PROFIL",
                    image: "/img/jumbotron-profile.png",
                    isBottomStyle: true
                }]}
            />
            <div className="profile-content-container">
                <Container className="profile py-5">
                    <Row>
                        <Col xs={12} className="mb-5">
                            <Row>
                                <Col xs={12} md={6} className="mb-5 mb-md-0">
                                    <img src="/img/profile-page-image-1.png" />
                                </Col>
                                <Col xs={12} md={6} className="py-md-5">
                                    <Row>
                                        <Col xs={12}>
                                            <TitlePage label="Profil" />
                                        </Col>
                                        <Col xs={12}>
                                            <Content
                                                description={
                                                    <>
                                                        Universitas Ekasakti merupakan salah satu perguruan tinggi swasta yang berada di bawah naungan Yayasan Perguruan Tinggi Indonesia (YPTI). Kini Yayasan Perguruan Tinggi Padang (YPTP, sejak 2008). Perguruan Tinggi ini didirikan dan dipimpin oleh Prof Dr H. Andi Mustari Pide, S.H (Alm), bersama dengan DR. Hj. Erawati Toelis, M.M (Alm), berdiri pada bulan April 1973 di Padang. <br /> <br />
                                                        Pada awalnya tahun 1973 itu, YPTI mendirikan Akademi Akuntansi Indonesia Padang. Selanjutnya pada tahun 1980, YPTI mendirikan Sekolah Tinggi Ekonomi Indonesia (STEI). Dan pada tahun 1984, YPTI mendirikan Universitas Ekasakti. Saat ini Universitas Ekasakti Padang memiliki 7 Fakultas yaitu Fakultas Ekonomi, Fakultas Hukum, Fakultas Teknik, Fakultas Keguruan dan Ilmu Pendidikan, Fakultas Pertanian, Fakultas Ilmu Sosial dan Ilmu Politik dan Fakultas Sastra dengan 21 Program Studi. <br /> <br />
                                                        Dua puluh Program Studinya terakreditasi B dan institusi juga terakreditasi B pada tahun 2018. Universitas Ekasakti mempunyai Program Pascasarjana Magister Hukum sejak tahun 2000. Kampusnya berada di pusat Kota Padang, dikenal dengan kampus merah maron. Kami senantiasa berupaya melakukan yang terbaik yang mampu kami lakukan. Sebagai ikhtiar ikut serta mewujudkan tujuan negara "mencerdaskan kehidupan bangsa. <br /> <br />
                                                    </>
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row className="align-items-stretch">
                                <Col xs={12} md={5} className="mb-4">
                                    <div className="profile-purpose-card">
                                        <img src="/img/visi-icon.svg" />
                                        <h1>Visi</h1>
                                        <p>Menjadi perguruan tinggi yang unggul dan mampu berperan aktif dalam pemngembangan pembangunan bangsa berkelanjutan pada tahun 2030.</p>
                                    </div>
                                </Col>
                                <Col xs={12} md={7} className="mb-4">
                                    <div className="profile-purpose-card">
                                        <img src="/img/misi-icon.svg" />
                                        <h1>Misi</h1>
                                        <ul>
                                            <li>Menyelenggarakan dan mengembangkan pendidikan berstandar nasional dan mengarah pada internasional.</li>
                                            <li>Mengembangkan dan menyebarluaskan ilmu pengetahuan, teknologi, manajemen dan budaya yang diakui secara nasional dan mengarah pada pengakuan internasional.</li>
                                            <li>Memanfaatkan ilmu pengetahuan, teknologi,  manajemen, budaya dan seni, untuk kesejahteraan dan kemajuan peradaban bangsa.</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col xs={12} md={7} className="mb-4">
                                    <div className="profile-purpose-card">
                                        <img src="/img/tujuan-icon.svg" />
                                        <h1>Tujuan</h1>
                                        <ul>
                                            <li>Tercapainya Kepercayaan dari seluruh pemanggku kepentingan.</li>
                                            <li>Menghasilkan  lulusan yang memiliki integritas, komptensi dan daya saing nasional dan internasional.</li>
                                            <li>Menghasilkan Budaya riset, atmosfir, akademik lintas budaya, dan jiwa kewirausahaan di kalangan sivitas akademika.</li>
                                            <li>Menghasilkan karya penelitian dan produk inovasi yang memberikan manfaat bagi peningkatan kualitas hidup masyarakat dan mendukung pembangunan ekonomi nasional secara berkelanjutan.</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col xs={12} md={5} className="mb-4">
                                    <div className="profile-purpose-card">
                                        <img src="/img/sasaran-icon.svg" />
                                        <h1>Sasaran</h1>
                                        <ul>
                                            <li>Terwujudnya tata kelola universitas, fakultas dan prodi berstandar universitas kelas nasional.</li>
                                            <li>Terimplementasinya pembelajaran aktif pada seluruh prodi.</li>
                                            <li>Meninggkatkan Penguasaan bahasa inggris pada kalangan dosen dan mahasiswa</li>
                                            <li>{`Terbentuknya Sikap (attitude), Perilaku (behavior), dan Nilai (value) unggul mahasiswa l`}</li>
                                            <li>Berkembangnya atmosfir kampus yang kondusif.</li>
                                            <li>Meningkatnya eksistensi lulusan pada pasar kerja nasional.</li>
                                            <li>Ditetapkannya Peruruan Tinggi.</li>
                                            <li>Di tetapkannya road map penelitian dan pengabdian bereputasi nasional.</li>
                                            <li>Berpartispasinya dosen UNES pada berbagai forum dan lembaga nasional.</li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Profile
