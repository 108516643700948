import { Button, Col, Container, Row } from "react-bootstrap"
import Jumbotron from "../components/Jumbotron"
import { NavLink } from "react-router-dom";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import "./BeritaDetail.css"

function BeritaDetail() {
    const news = [{
        title: "BRI Cabang Padang Bantu Beasisiwa Rp 50 Juta Mahasiswa UNES-AAI",
        image: "/img/news-1.png",
        date: "17 Juli 2020"
    }, {
        title: "Lawan Berita Hoax, Hima Administrasi Negara UNES Gelar Seminar Nasional",
        image: "/img/news-2.png",
        date: "1 November 2019"
    }, {
        title: "Prodi THP Fakultas Pertanian UNES Gelar Workshop Mendeley ",
        image: "/img/news-3.png",
        date: "9 Oktober 2019"
    }]

    return (
        <div>
            <Jumbotron
                slides={[{
                    title: "BERITA",
                    image: "/img/jumbotron-news.png",
                    isBottomStyle: true
                }]}
            />
            <div className="news-detail-content-container">
                <Container className="py-5">
                    <Row>
                        <Col xs={12} className="mb-5 news-detail">
                            <Row>
                                <Col xs={12} className="mb-5">
                                    <h1 className="mb-2">Andaleh Nagari Binaan UNES-AAI Produksi Roti Bakery, Aluo dan Rakik</h1>
                                    <div className="toolbar d-flex align-items-center">
                                        <Row>
                                            <Col md className="d-flex align-items-center me-5">
                                                <img src="/img/icon/clock-icon.svg" />
                                                <p className="text-nowrap">17 Juli 2020</p>
                                            </Col>
                                            <Col md className="d-flex align-items-center me-5">
                                                <img src="/img/icon/person-icon.svg" />
                                                <p className="text-nowrap">Oleh : Admin</p>
                                            </Col>
                                            <Col md className="d-flex align-items-center me-5">
                                                <img src="/img/icon/eye-icon.svg" />
                                                <p className="text-nowrap">120</p>
                                            </Col>
                                            <Col md className="d-flex align-items-center me-5">
                                                <img src="/img/icon/comment-icon.svg" />
                                                <p className="text-nowrap">1</p>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col xs={12} className="mb-3">
                                    <img className="news-content-image" src="/img/main-news-1.png" />
                                    <p className="news-contents">
                                        Padang, PJ. Wali Nagari Andaleh, Ketua LKAM Batipuh, Tokoh Masyarakat serta Aparaturnya berkunjung ke Universitas Ekasakti, kunjungan itu diterima Rektor didampingi Wakil Rektor I, Dekan Teknik dan Direktur AAI Padang di Ruang Sidang Rektorat Universitas tersebut. Rabu (8/7) kemarin. <br /> <br />
                                        PJ. Wali Nagari Andaleh Kecamatan Batipuh Kab. Tanah Datar Ahmad Sauri bersyukur nagarinya dijadikan daerah binaan Universitas Ekasakti sejak tahun l983 sampai sekarang, atas kerjasama Lembaga Penelitian Pengabdian Kepada Masyarakat (LPPM) UNES dengan Nagari Andaleh, saat ini masyarakatnya telah memproduksi makanan berupa roti Bakeri Andaleh, Aluo dan Rakik, daerah pemasarannya Padang Panjang, Bikittinggi dan Batusangkar dan beberapa daerah di Sumatera Barat. <br /> <br />
                                        Pada Sektor Pertanian, masyarakat Nagari Andaleh lebih banyak bertanam sayuran dan bunga hias sesuai dengan kondisi daerahnya, perekonomian masyarakat stabil karena masyarakatnya bertani tidak ada pengaruh dari virus covid 19. Nagari ini aman dan tidak satupun terkapar virus Covid 19.  <br /> <br />
                                        Selain itu daerah ini juga cocok dijadikan daerah wisata, sekarang sudah banyak dikunjungi masyarakat baik dalam daerah maupun  luar daerah Sumatera Barat malah ada dari Malaysia, kata Ahmad Sauri. <br /><br />
                                        Tahun 2006 Nagari Andaleh memperoleh penghargaan Nagari Berprestasi Tingkat Nasional, kini juga dinobatkan dengan julukan Nagari Tageh Rumah Gadang, karena ketangguhannya di masa covid. Dalam peningkatan perekonomian masyarakat wali nagari minta agar Universitas Ekasakti bisa memfasilitasi dan pembinaan di sektor pertanian. <br /><br />
                                        Sementara Ketua LKAM Batipuh H. Angku Ladang selaku Tokoh Masyarakat menyampaikan aspirasi masyarakatnya agar pihak UNES-AAI bisa memberikan keringanan uang kuliah, sehingga anak-anaknya dapat melanjutkan pendidikan ke Perguruan Tinggi. <br /><br />
                                        Hal ini direspon oleh Rektor Dr. Otong Rosadi, SH, M.Hum, UNES-AAI akan memberikan fasilitas keringanan uang kuliah sebagaimana tahun-tahun sebelumnya dan kita nanti akan memfasilitasi ke Nagari Andaleh.<br /><br />
                                        Kemudian Pj. Wali Nagari dan Ketua LKAM Batipuh memperkenalkan produk makanan berupa roti Bakeri Andaleh, Alua dan Rakik yang dibuat masyarakat Nagari Andaleh kepada Pimpinan Universitas Ekasakti. <br /><br />
                                        {`Dari Ruang Sidang Rektorat UNES Padang, Jl. Veteran Dalam No. 26B, Padang Pasir, Padang Baru Padang Sumatra Barat, Rabu (8/7), demikian Ka. Humas UNES-AAI Padang. (Humas)`}
                                    </p>
                                </Col>
                                <Col xs={12}>

                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <div className="mb-5">
                                <h1 className="more-news">Berita Lainnya</h1>
                            </div>
                            <OwlCarousel
                                className="owl-theme"
                                margin={30}
                                dots={false}
                                responsive={{
                                    0: {
                                        items: 1
                                    },
                                    600: {
                                        items: 2
                                    },
                                    800: {
                                        items: 3
                                    }
                                }}
                            >
                                {news.map((datum, index) =>
                                    <div key={index} className="news-card d-flex align-items-end" style={{ backgroundImage: `url('${datum.image}')` }}>
                                        <div className="news-detail-info">
                                            <p className="title">{datum.title}</p>
                                            <p className="date">{datum.date}</p>
                                            <NavLink className="read-more" to="/detail-berita">Baca Selengkapnya</NavLink>
                                        </div>
                                    </div>
                                )}
                            </OwlCarousel>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default BeritaDetail
