import { Outlet } from "react-router"
import Footer from "./fragments/Footer"
import Header from "./fragments/Header"

import "./App.css"
import InfoBanner from "./fragments/InfoBanner"
import { useEffect } from "react"

function App() {

    const handleScroll = () => {
        const root = document.getElementById("root")

        if (root.scrollTop > 100) {
            document.getElementById("unes-navbar").classList.add("scroll")
            document.getElementById("info-banner-navbar").classList.add("d-none")
        } else {
            document.getElementById("unes-navbar").classList.remove("scroll")
            document.getElementById("info-banner-navbar").classList.remove("d-none")
        }
    }

    useEffect(() => {
        document.getElementById("root")?.addEventListener("scroll", handleScroll)
    }, [])

    return (
        <div className="App">
            <InfoBanner />
            <Header />
            <Outlet />
            <Footer />
        </div>
    )
}

export default App
