import { Facebook, Instagram, Twitter, YouTube } from "@mui/icons-material"
import { Carousel } from "react-bootstrap"
import { Link } from 'react-router-dom'
import "./Jumbotron.css"

function Jumbotron({
    slides = [],
    withSocialMedia = false,
    withNews = false
}) {
    return (
        <div className="jumbotron-container">
            {slides?.map((slide, index) =>
                <Carousel key={index} controls={false} indicators={slides.length > 1}>
                    <Carousel.Item>
                        <div
                            className={`jumbotron-carousel-slide d-flex ${slide.isBottomStyle ? "align-items-end" : "align-items-center"}`}
                            style={{ backgroundImage: `url('${slide.image}')` }}
                        >
                            <Carousel.Caption className="container carousel-caption" style={{ paddingBottom: slide.isBottomStyle ? "10%" : "" }}>
                                {slide.isBottomStyle &&
                                    <div className="custom-border-top" />
                                }
                                <p className="title mb-md-2">{slide.title}</p>
                                <p className={`subtitle ${slide.isBottomStyle ? "bottom-style" : ""}`}>{slide.subtitle}</p>
                            </Carousel.Caption>
                        </div>
                    </Carousel.Item>
                </Carousel>
            )}
            {withSocialMedia &&
                <div className="social-media-container">
                    <div className="container-fluid">
                        <div className="social-media-list">
                            <a href="#" target="_blank">
                                <Facebook />
                            </a>
                            <a href="#" target="_blank">
                                <Instagram />
                            </a>
                            <a href="#" target="_blank">
                                <YouTube />
                            </a>
                        </div>
                    </div>
                </div>
            }
            {withNews &&
                <div className="stepper-container">
                    <div className="stepper">
                        <p className="title">01.</p>
                        <p className="description">Andaleh Nagari Binaan
                            UNES-AAI Produksi Roti
                            Bakery, Aluo dan Rakik</p>
                        <p className="date">17 Juli 2020</p>
                        <Link
                            to="/berita/detail"
                        >
                            Baca Selengkapnya
                        </Link>
                    </div>
                    <div className="stepper">
                        <p className="title">02.</p>
                        <p className="description">BRI Cabang Padang Bantu Beasisiwa Rp 50 Juta Mahasiswa UNES-AAI</p>
                        <p className="date">17 Juli 2020</p>
                        <Link
                            to="/berita/detail"
                        >
                            Baca Selengkapnya
                        </Link>
                    </div>
                    <div className="stepper">
                        <p className="title">03.</p>
                        <p className="description">Lawan Berita Hoax, Hima Administrasi Negara UNES Gelar Seminar Nasional</p>
                        <p className="date">1 November 2019</p>
                        <Link
                            to="/berita/detail"
                        >
                            Baca Selengkapnya
                        </Link>
                    </div>
                    <div className="stepper">
                        <p className="title">04.</p>
                        <p className="description">Prodi THP Fakultas Pertanian UNES Gelar Workshop Mendeley </p>
                        <p className="date">9 Oktober 2019</p>
                        <Link
                            to="/berita/detail"
                        >
                            Baca Selengkapnya
                        </Link>
                    </div>
                    <div className="more-news">
                        <Link to="/berita">
                            <img src="/img/icon/chevron-circle.svg" />
                        </Link>
                    </div>
                </div>
            }
        </div >
    )
}

export default Jumbotron
