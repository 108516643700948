import MenuIcon from '@mui/icons-material/Menu'
import { useEffect, useState } from 'react'
import { Button, Container, Nav, Navbar, Offcanvas } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'

function Header() {
    const [activeMenu, setActiveMenu] = useState(window.location.pathname)
    let location = useLocation();

    const isMenuActive = currentMenu => {
        return String(activeMenu).includes(currentMenu)
    }

    useEffect(() => {
        setActiveMenu(location.pathname)

        const root = document.getElementById("root")

        if (root) {
            root.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    }, [location.pathname])

    return (
        <Navbar id="unes-navbar" fixed="top" bg="transparent" expand="lg" className="mb-3 unes-navbar">
            <Container>
                <Navbar.Brand href="/">
                    <img
                        src="/img/unes_logo.svg"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        alt="Unes logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={`unes-navbar-expand`}>
                    <MenuIcon />
                </Navbar.Toggle>
                <Navbar.Offcanvas
                    id={`unes-navbar-expand`}
                    aria-labelledby={`unes-navbarLabel-expand`}
                    placement="end"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`unes-navbarLabel-expand`}>
                            Unes
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            <Link
                                className={`nav-link ${isMenuActive("profile") ? "active" : ""}`}
                                to="profile"
                            >
                                Profil
                            </Link>
                            <Link
                                className={`nav-link ${isMenuActive("akademik") ? "active" : ""}`}
                                to="akademik"
                            >
                                Akademik
                            </Link>
                            <a
                                className={`nav-link ${isMenuActive("pendaftaran-dan-beasiswa") ? "active" : ""}`}
                                href="https://dev-pmb.unespadang.ac.id/"
                                target="_blank"
                            >
                                Pendaftaran & Beasiswa
                            </a>
                            <Link
                                className={`nav-link ${isMenuActive("penelitian-dan-pengabdian") ? "active" : ""}`}
                                to="penelitian-dan-pengabdian"
                            >
                                Penelitian & Pengabdian
                            </Link>
                            <Link
                                className={`nav-link ${isMenuActive("kalender-akademik") ? "active" : ""}`}
                                to="kalender-akademik"
                            >
                                Kalender Akademik
                            </Link>
                            <Link
                                className={`nav-link ${isMenuActive("repository") ? "active" : ""}`}
                                to="kalender-akademik"
                            >
                                Repository
                            </Link>
                            <Link
                                className={`nav-link ${isMenuActive("kelas-digital") ? "active" : ""}`}
                                to="kelas-digital"
                            >
                                Kelas Digital
                            </Link>
                        </Nav>
                        <Button className="unes-btn">LOGIN</Button>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}

export default Header
